import { FC, useState } from 'react';
import NavbarWithMenu from '../../components/navbarWithMenu';
import Container from '../../components/container';

import searchLogo from '../../common/assets/images/mygrdc/searchMenuGray.svg';
import mouseImage from '../../common/assets/images/mygrdc/mouse.svg';
import harvestImage from '../../common/assets/images/mygrdc/harvest.svg';
import frostImage from '../../common/assets/images/mygrdc/frost.svg';
import snailImage from '../../common/assets/images/mygrdc/snail.svg';

import Events from './eventsSection';
import Footer from './footer';
import LatestNews from './latestNewsSection';
import dashboardImage from '../../common/assets/images/mygrdc/dashboard-img.png';

import LatestVideosAndPodcasts from './latestVideosAndPodcastsSection';
import TrendingTopics from './trendingTopicsSection';
import LatestReleases from './latestReleases';
import { useNavigate } from 'react-router-dom';
import errorIcon from '../../common/assets/images/mygrdc/error.svg';
import { validateSearchInput } from '@/utils/helpers';

const Dashboard = () => {
  return (
    <Container navbar={<NavbarWithMenu />}>
      <div className="bg-primaryTeal-100">
        <HeroSection />
        <WhatAreYouLookingFor />
        <TrendingTopics />
        <LatestReleases />
        <Events />
        <LatestNews />
        <LatestVideosAndPodcasts />
        <Footer />
      </div>
    </Container>
  );
};

export default Dashboard;

const HeroSection: FC = () => {
  const [searchText, setSearchText] = useState('');
  const [errorMessage, setErrrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchText.trim() && !errorMessage) {
      navigate(`/search?query=${encodeURIComponent(searchText)}`);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchText.trim() && !errorMessage) {
      handleSearch();
    }
  };

  const handleSearchInputChange = (e: any) => {
    const text = e.target.value;
    setSearchText(text);
    const validationError = validateSearchInput(text);
    setErrrorMessage(validationError);
  };

  return (
    <div className="h-[465px]">
      <div className="bg-tint-light h-[336px] relative">
        <div className="container flex justify-center mx-auto">
          <div className="relative z-20 flex-1 mt-2 space-y-10">
            <div className="font-bold text-[28px] sm:text-[36px] md:text-[44px] leading-[36px] sm:leading-[46px] md:leading-[53.64px] text-left text-white max-w-full sm:max-w-[600px] tracking-wide m-4">
              Enduring profitability for grain growers
            </div>

            <div className="mt-8 relative flex items-center bg-white rounded-xl shadow-md md:max-w-[700px] lg:max-w-[903px] px-4 sm:px-6 py-3  h-16 sm:h-20 m-4 sm:m-4">
              {/* Search Icon */}
              <div className="flex items-center">
                <img
                  src={searchLogo}
                  width={40}
                  alt="Search Icon"
                  className="rounded-bl-[150px] min-h-[417px]"
                />
              </div>

              {/* Input Field */}
              <input
                type="text"
                placeholder="Search resources, events & more"
                value={searchText}
                onChange={handleSearchInputChange}
                onKeyDown={handleKeyDown}
                className="w-full flex-grow pl-2 md:pl-4 py-2 md:py-3 placeholder-neutral-600  placeholder:font-regular outline-none h-full rounded-md font-regular"
              />

              {/* Search Button */}
              <button
                onClick={handleSearch}
                disabled={!searchText.trim()}
                className="h-full px-4 sm:px-14 text-white rounded-md bg-device-green hidden sm:block"
              >
                Search
              </button>
            </div>
            {errorMessage && (
              <div className="w-full px-6 sm:px-2 md:px-4 z-20">
                <div
                  className={`flex items-center text-semanticsError-500 font-regular text-sm -mt-6 z-20 
                    ${searchText ? 'mb-32' : 'mb-20 md:mb-18'}
                 bg-red-100 px-4 py-2 rounded-lg w-full min-h-14 max-w-lg`}
                >
                  <img
                    src={errorIcon}
                    alt="Error icon"
                    className="-mt-4 lg:-mt-3 lg:mb-1 mr-2 h-4 w-4 rounded-full"
                  />
                  <span className="text-left">{errorMessage}</span>
                </div>
              </div>
            )}
          </div>

          <div className="absolute top-48 w-full max-w-full md:top-0 md:right-0 md:w-1/2 md:h-72 pl-8 sm:pl-0">
            <img
              src={dashboardImage}
              alt="Grain growers"
              className="w-full h-72 md:h-96 lg:h-[400px] xl:h-[450px] object-cover md:min-h-62 rounded-bl-[100px] md:rounded-bl-[150px] object-top"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const WhatAreYouLookingFor: FC = () => {
  return (
    <div className="container mt-8 md:mt-0 mx-auto">
      <div className="text-left font-bold text-3xl leading-[36px] mx-4">
        What are you looking for?
      </div>
      <div className="grid grid-cols-1 gap-5 mt-8 lg:grid-cols-2 xl:grid-cols-4 mx-4">
        <a
          href="https://grdc.com.au/resources-and-publications/resources/mouse-management"
          target="_blank"
          rel="noopener noreferrer"
          className="block"
        >
          <CardWithImage imageSrc={mouseImage} text="Mouse management" />
        </a>

        <a
          href="https://grdc.com.au/resources-and-publications/resources/harvest-resources"
          target="_blank"
          rel="noopener noreferrer"
          className="block"
        >
          <CardWithImage imageSrc={harvestImage} text="Harvest resources" />
        </a>

        <a
          href="https://grdc.com.au/resources-and-publications/resources/frost-management"
          target="_blank"
          rel="noopener noreferrer"
          className="block"
        >
          <CardWithImage imageSrc={frostImage} text="Frost management" />
        </a>

        <a
          href=" https://grdc.com.au/resources-and-publications/resources/slugs-and-snails"
          target="_blank"
          rel="noopener noreferrer"
          className="block"
        >
          <CardWithImage imageSrc={snailImage} text="Snails and slugs" />
        </a>
      </div>
    </div>
  );
};

const CardWithImage = ({
  imageSrc,
  text,
}: {
  imageSrc: string;
  text: string;
}) => {
  return (
    <div className="min-w-[288px] px-5 py-8 bg-white flex flex-col items-center justify-center rounded-lg hover:cursor-pointer">
      <img src={imageSrc} alt="Feed" width={64} />
      <div className="text-center mt-4 font-bold text-[16px] leading-[20px]">
        {text}
      </div>
    </div>
  );
};
